import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MasterLogin from './pages/master/LoginPage';
import MasterDashboard from './pages/master/DashboardPage';
import KlinikDashboard from './pages/klinik/DashboardPage';
import KlinikDataPerujuk from './pages/klinik/DataPerujukPage';
import KlinikPasien from './pages/klinik/PasienPage';
import KlinikTransaksiPasien from './pages/klinik/TransaksiPasienPage';
import CRMDashboard from './pages/crm/DashboardPage';
import CRMPipeline from './pages/crm/PipelinePage';
import WebsiteDashboard from './pages/website/DashboardPage';
import PrivateRoute from './components/master/PrivateRoute';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* Route publik */}
          <Route path="/" element={<MasterLogin />} />
          {/* Route private */}
          <Route path="/dashboard" element={<PrivateRoute><MasterDashboard /></PrivateRoute>} />
          <Route path="/klinik/dashboard" element={<PrivateRoute><KlinikDashboard /></PrivateRoute>} />
          <Route path="/klinik/data-rujukan" element={<PrivateRoute><KlinikDataPerujuk /></PrivateRoute>} />
          <Route path="/klinik/transaksi-pasien" element={<PrivateRoute><KlinikTransaksiPasien /></PrivateRoute>} />
          <Route path="/klinik/pasien" element={<PrivateRoute><KlinikPasien /></PrivateRoute>} />
          <Route path="/crm/dashboard" element={<PrivateRoute><CRMDashboard /></PrivateRoute>} />
          <Route path="/crm/pipeline" element={<PrivateRoute><CRMPipeline /></PrivateRoute>} />
          <Route path="/admin-web/dashboard" element={<PrivateRoute><WebsiteDashboard /></PrivateRoute>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
