import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const checkAuthentication = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/verifyAuth`, {
            method: 'GET',
            credentials: 'include',
        });

        if (response.ok) {
            const data = await response.json();
            return data.message === 'Authenticated';
        }

        return false;
    } catch (error) {
        console.error('Authentication check failed:', error);
        return false;
    }
};

const PrivateRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const verifyAuthStatus = async () => {
            const authenticated = await checkAuthentication();
            setIsAuthenticated(authenticated);
        };

        verifyAuthStatus();
    }, []);

    if (isAuthenticated === null) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    return children;
};

export default PrivateRoute;
