const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const VALID_STATUSES = ['NEW', 'FOLLOW-UP', 'IN-PROCESS', 'CLOSED'];

// Fungsi untuk refresh token jika expired
const refreshAccessToken = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/auth/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error("Failed to refresh token");
        }

        const data = await response.json();
        return data.accessToken;
    } catch (error) {
        console.error("Error refreshing access token:", error);
        throw error;
    }
};

// Fungsi untuk mengambil data pipeline berdasarkan status
export const fetchPipelineData = async (status) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/crm/pipeline/${status}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (response.status === 401) {
            const newAccessToken = await refreshAccessToken();
            return fetchPipelineDataWithNewToken(newAccessToken, status);
        }

        if (!response.ok) {
            throw new Error("Failed to fetch pipeline data!");
        }

        const data = await response.json();
        return data.payload;
    } catch (error) {
        console.error("Error fetching pipeline data:", error);
        throw error;
    }
};

// Fungsi untuk mengambil data pipeline dengan token yang baru
const fetchPipelineDataWithNewToken = async (newAccessToken, status) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/crm/pipeline/${status}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${newAccessToken}`,
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error("Failed to fetch pipeline data with new token!");
        }

        const data = await response.json();
        return data.payload;
    } catch (error) {
        console.error("Error fetching pipeline data with new token:", error);
        throw error;
    }
};

// Fungsi untuk menambah pipeline baru
export const insertPipeline = async (pipelineData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/crm/pipeline`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(pipelineData),
            credentials: 'include',
        });

        if (response.status === 401) {
            const newAccessToken = await refreshAccessToken();
            return insertPipelineWithNewToken(newAccessToken, pipelineData);
        }

        if (!response.ok) {
            throw new Error("Failed to add pipeline!");
        }

        const data = await response.json();
        return data.payload;
    } catch (error) {
        console.error("Error adding pipeline:", error);
        throw error;
    }
};

// Fungsi untuk menambah pipeline dengan token baru
const insertPipelineWithNewToken = async (newAccessToken, pipelineData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/crm/pipeline`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${newAccessToken}`,
            },
            body: JSON.stringify(pipelineData),
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error("Failed to add pipeline with new token!");
        }

        const data = await response.json();
        return data.payload;
    } catch (error) {
        console.error("Error adding pipeline with new token:", error);
        throw error;
    }
};

// Fungsi untuk mengupdate pipeline
export const updatePipeline = async (pipelineId, updatedData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/crm/pipeline/${pipelineId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
            credentials: 'include',
        });

        if (response.status === 401) {
            const newAccessToken = await refreshAccessToken();
            return updatePipelineWithNewToken(newAccessToken, pipelineId, updatedData);
        }

        if (!response.ok) {
            throw new Error("Failed to update pipeline!");
        }

        const data = await response.json();
        return data.payload;
    } catch (error) {
        console.error("Error updating pipeline:", error);
        throw error;
    }
};

// Fungsi untuk mengupdate pipeline dengan token baru
const updatePipelineWithNewToken = async (newAccessToken, pipelineId, updatedData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/crm/pipeline/${pipelineId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${newAccessToken}`,
            },
            body: JSON.stringify(updatedData),
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error("Failed to update pipeline with new token!");
        }

        const data = await response.json();
        return data.payload;
    } catch (error) {
        console.error("Error updating pipeline with new token:", error);
        throw error;
    }
};

// Fungsi untuk menghapus pipeline
export const deletePipeline = async (pipelineId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/crm/pipeline-delete/${pipelineId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (response.status === 401) {
            const newAccessToken = await refreshAccessToken();
            return deletePipelineWithNewToken(newAccessToken, pipelineId);
        }

        if (!response.ok) {
            throw new Error("Failed to delete pipeline!");
        }

        const data = await response.json();
        return data.message;
    } catch (error) {
        console.error("Error deleting pipeline:", error);
        throw error;
    }
};

const deletePipelineWithNewToken = async (newAccessToken, pipelineId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/crm/pipeline-delete/${pipelineId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${newAccessToken}`,
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error("Failed to delete pipeline with new token!");
        }

        const data = await response.json();
        return data.message;
    } catch (error) {
        console.error("Error deleting pipeline with new token:", error);
        throw error;
    }
};

export const updatePipelineStatus = async (pipelineId, status, updated_by) => {
    // Validate if the status is valid
    if (!VALID_STATUSES.includes(status)) {
        throw new Error(`Invalid status: ${status}`);
    }

    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/crm/pipeline-status/${pipelineId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                status: status,
                updated_by: updated_by,
            }),
            credentials: 'include',
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error("Failed to update pipeline:", errorData);
            throw new Error(`Failed to update pipeline status! ${errorData.message || response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error updating pipeline status:", error);
        throw error; // rethrow to propagate
    }
};    