import React, { useState } from 'react';
import {
    Box,
    Typography,
    IconButton,
    AppBar,
    Toolbar,
    Menu,
    Container,
    Tooltip,
    MenuItem,
    Card,
    CardActionArea,
    CardMedia,
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import Background from '../../assets/master/background.jpg';
import Logo from '../../assets/master/logo-rs.png';
import IconCRM from '../../assets/master/crm.png';
import IconWebsite from '../../assets/master/website.png';
import IconKars from '../../assets/master/kars.png';
import IconKoperasi from '../../assets/master/koperasi.png';
import IconPreparemom from '../../assets/master/preparemom.png';
import IconClipboard from '../../assets/master/clipboard.png';
import IconAdminWeb from '../../assets/master/admin-web.png';
import IconEmail from '../../assets/master/email.png';

const settings = ['Profile', 'Logout'];

const cardData = [
    { icon: IconClipboard, bgColor: '#EA906C', label: 'Laporan' },
    { icon: IconCRM, bgColor: '#536493', label: 'CRM', link: '/crm/pipeline' },
    { icon: IconAdminWeb, bgColor: '#8ABFA3', label: 'Admin Web', link: '/admin-web/dashboard' },
    { icon: IconKoperasi, bgColor: '#624E88', label: 'Koperasi', link: 'https://koperasi.bhaktiasih.co.id' },
    { icon: IconKars, bgColor: '#bfb56b', label: 'Sidokar', link: 'https://sidokar.bhaktiasih.co.id' },
    { icon: IconPreparemom, bgColor: '#cb6483', label: 'Prepare!mom', link: 'https://preparemom.bhaktiasih.co.id' },
    { icon: IconWebsite, bgColor: '#A5BECC', label: 'Website', link: 'https://rsbhaktiasih.com' },
    { icon: IconEmail, bgColor: '#9D5353', label: 'Email', link: 'https://mail.hostinger.com/' },
];

export const Dashboard = () => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [openClipboardModal, setOpenClipboardModal] = useState(false);
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/logout`, {
                method: 'POST',
                credentials: 'include',
            });

            const data = await response.json();
            if (response.ok) {
                console.log('Logged out successfully');
                localStorage.clear();
                navigate('/');
            } else {
                console.error('Logout failed', data.message);
            }
        } catch (error) {
            console.error('Error during logout', error);
        }
    };

    const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
    const handleCloseUserMenu = () => setAnchorElUser(null);

    const handleCardClick = (link, label) => {
        if (label === 'Laporan') {
            // Jika label adalah 'Laporan', buka modal
            setOpenClipboardModal(true);
        } else {
            // Jika bukan 'Laporan', arahkan ke link
            window.open(link, '_blank', 'width=800,height=600');
        }
    };

    // Fungsi untuk membuka dan menutup modal
    const handleOpenClipboardModal = () => setOpenClipboardModal(true);
    const handleCloseClipboardModal = () => setOpenClipboardModal(false);

    // Fungsi untuk navigasi atau membuka link saat memilih menu
    const handleMenuClick = (link, label) => {
        if (label === 'Menu Satu') {
            // Jika memilih Menu Satu, buka link dengan window.open
            window.open(link, '_blank', 'width=800,height=600');
        }
        // Menutup modal setelah memilih menu
        handleCloseClipboardModal();
    };

    return (
        <Box sx={{ height: '100vh', overflow: 'hidden' }}>
            <AppBar position="absolute" sx={{ background: 'transparent', color: '#00712D', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <img src={Logo} alt="Logo" style={{ width: 60, marginRight: 8 }} />
                        <Typography
                            variant="h6"
                            component="a"
                            href="/"
                            sx={{
                                ml: 2,
                                fontSize: '28px',
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                color: 'inherit',
                                textDecoration: 'none'
                            }}
                        >
                            RS BHAKTI ASIH
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Tooltip title="Profile">
                            <IconButton onClick={handleOpenUserMenu}>
                                <AccountCircleIcon sx={{ fontSize: 35, color: '#00712D' }} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            anchorEl={anchorElUser}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                            {settings.map((setting) => (
                                <MenuItem
                                    key={setting}
                                    onClick={setting === 'Logout' ? handleLogout : handleCloseUserMenu}
                                >
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url(${Background})`,
                    backgroundSize: 'cover',
                    position: 'relative',
                    overflowY: 'auto'
                }}
            >
                <Box sx={{ zIndex: 2, position: 'relative', maxWidth: '700px', width: '100%', mt: { xs: '750px', sm: '100px', md: '55px' } }}>
                    <Grid container spacing={8} justifyContent="center" >
                        {cardData.map((card, index) => (
                            <Grid key={index} item xs={12} sm={6} md={3} display="flex" flexDirection="column" alignItems="center">
                                <Card sx={{ width: 100, height: 100, backgroundColor: card.bgColor }}>
                                    <CardActionArea
                                        onClick={() => handleCardClick(card.link, card.label)} // Pass label as well
                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                                    >
                                        <CardMedia component="img" image={card.icon} alt="icon" sx={{ height: 65, width: 65, objectFit: 'contain' }} />
                                    </CardActionArea>
                                </Card>
                                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                    {card.label}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
            <Dialog open={openClipboardModal} onClose={handleCloseClipboardModal}
                sx={{ '& .MuiDialog-paper': { width: '600px', maxWidth: '90%' } }} >
                <DialogTitle>Laporan</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseClipboardModal}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Button fullWidth onClick={() => handleMenuClick('/klinik/data-rujukan', 'Menu Satu')} sx={{ textTransform: 'none' }}>
                        Klinik - Pasien Dirujuk
                    </Button>
                    <Button fullWidth onClick={handleCloseClipboardModal} sx={{ textTransform: 'none' }}>
                        Rumah Sakit - Kunjungan Pasien IGD
                    </Button>
                    <Button fullWidth onClick={handleCloseClipboardModal} sx={{ textTransform: 'none' }}>
                        Rumah Sakit - Kunjungan Pasien Rawat Jalan
                    </Button>
                    <Button fullWidth onClick={handleCloseClipboardModal} sx={{ textTransform: 'none' }}>
                        Rumah Sakit - Kunjungan Pasien Rawat Inap
                    </Button>
                    <Button fullWidth onClick={() => handleMenuClick('/klinik/transaksi-pasien', 'Menu Satu')} sx={{ textTransform: 'none' }}>
                        Rumah Sakit - Transaksi Pasien Detail
                    </Button>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default Dashboard;