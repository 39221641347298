import React, { useState, useEffect } from 'react';
import { Modal, Box, Grid2, Card, Typography, Button } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { fetchPipelineData, updatePipelineStatus, deletePipeline } from '../../api/crmAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPhone, faPenToSquare, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { grey } from '@mui/material/colors';

const Pipeline = () => {
    const [newPipelines, setNewPipelines] = useState([]);
    const [followUpPipelines, setFollowUpPipelines] = useState([]);
    const [inProcessPipelines, setInProcessPipelines] = useState([]);
    const [closedPipelines, setClosedPipelines] = useState([]);
    const [openModalPipeline, setOpenModalPipeline] = useState(false);
    const [selectedPipeline, setSelectedPipeline] = useState(null);

    useEffect(() => {
        const loadPipelines = async () => {
            const newPipelineData = await fetchPipelineData("NEW");
            const followUpPipelineData = await fetchPipelineData("FOLLOW-UP");
            const inProcessPipelineData = await fetchPipelineData("IN-PROCESS");
            const closedPipelineData = await fetchPipelineData("CLOSED");

            setNewPipelines(newPipelineData);
            setFollowUpPipelines(followUpPipelineData);
            setInProcessPipelines(inProcessPipelineData);
            setClosedPipelines(closedPipelineData);
        };

        loadPipelines();
    }, []);

    const onDragEnd = async (result) => {
        const { source, destination } = result;

        if (!destination) return;

        const sourceList = getPipelineListById(source.droppableId);
        const destinationList = getPipelineListById(destination.droppableId);

        const draggedPipeline = sourceList[source.index];

        if (!draggedPipeline) {
            console.error("No pipeline found to move");
            return;
        }

        const updatedSource = [...sourceList];
        updatedSource.splice(source.index, 1);

        const updatedDestination = [...destinationList];
        updatedDestination.splice(destination.index, 0, draggedPipeline);

        setStateByListId(destination.droppableId, updatedDestination);
        setStateByListId(source.droppableId, updatedSource);

        const updated_by = "18128C75-F64B-4A0C-8C9C-B3758867313A";

        let updatedStatus = destination.droppableId.toUpperCase();
        if (updatedStatus === 'FOLLOWUP') {
            updatedStatus = 'FOLLOW-UP';
        } else if (updatedStatus === 'INPROCESS') {
            updatedStatus = 'IN-PROCESS';
        }

        try {
            await updatePipelineStatus(draggedPipeline.id, updatedStatus, updated_by);
        } catch (error) {
            console.error('Error updating pipeline status:', error);
            alert('Failed to update pipeline status. Please try again.');
        }
    };

    const getPipelineListById = (droppableId) => {
        switch (droppableId) {
            case "new": return newPipelines;
            case "followUp": return followUpPipelines;
            case "inProcess": return inProcessPipelines;
            case "closed": return closedPipelines;
            default: return [];
        }
    };

    const setStateByListId = (droppableId, updatedList) => {
        switch (droppableId) {
            case "new": setNewPipelines(updatedList); break;
            case "followUp": setFollowUpPipelines(updatedList); break;
            case "inProcess": setInProcessPipelines(updatedList); break;
            case "closed": setClosedPipelines(updatedList); break;
            default: break;
        }
    };

    const handleDeletePipeline = async (pipelineId, status) => {
        try {
            await deletePipeline(pipelineId);
            switch (status) {
                case 'NEW':
                    setNewPipelines(newPipelines.filter(pipeline => pipeline.id !== pipelineId));
                    break;
                case 'FOLLOW-UP':
                    setFollowUpPipelines(followUpPipelines.filter(pipeline => pipeline.id !== pipelineId));
                    break;
                case 'IN-PROCESS':
                    setInProcessPipelines(inProcessPipelines.filter(pipeline => pipeline.id !== pipelineId));
                    break;
                case 'CLOSED':
                    setClosedPipelines(closedPipelines.filter(pipeline => pipeline.id !== pipelineId));
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error("Error deleting pipeline:", error);
        }
    };

    const handleCardClick = (pipeline) => {
        setSelectedPipeline(pipeline);
        setOpenModalPipeline(true);
    };

    const handleCloseModalPipeline = () => {
        setOpenModalPipeline(false);
    };

    const getTypeBackgroundColor = (type) => {
        switch (type) {
            case 'Komplain':
                return '#FFCDD2';
            case 'Lain-lain':
                return '#FFF9C4';
            case 'Kontrol Post Rawat Inap':
                return '#C8E6C9';
            case 'Appointment':
                return '#BBDEFB';
            default:
                return 'transparent';
        }
    };

    const getTypeTextColor = (type) => {
        switch (type) {
            case 'Komplain':
                return '#D32F2F';
            case 'Lain-lain':
                return '#F57C00';
            case 'Kontrol Post Rawat Inap':
                return '#388E3C';
            case 'Appointment':
                return '#1976D2';
            default:
                return '#000';
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 40px)', overflow: 'hidden' }}>
            <Card sx={{
                backgroundColor: 'background.paper',
                width: '100%',
                height: '80px',
                border: `1px solid ${grey[200]}`,
                boxShadow: 'none'
            }}>
            </Card>
            <DragDropContext onDragEnd={onDragEnd}>
                <Grid2 container spacing={1.5} sx={{ overflow: 'hidden', height: '100%', marginTop: 2 }}>
                    <Grid2 size={2.4} sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                        <Card sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px',
                            backgroundColor: '#fff',
                            position: 'relative',
                            color: '#ddd',
                            borderRadius: '4px 4px 0 0',
                            border: `1px solid ${grey[200]}`,
                            boxShadow: 'none',
                            height: '50px',
                        }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                New
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingLeft: '5px' }}>
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    style={{ fontSize: '1.5rem', cursor: 'pointer', color: '#ddd' }}
                                />
                            </Box>
                        </Card>
                        <Card sx={{
                            backgroundColor: '#fff',
                            boxShadow: 'none',
                            position: 'relative',
                            flexGrow: 1,
                            padding: 1.5,
                            border: `1px solid ${grey[200]}`,
                            borderTop: 'none',
                            marginTop: -1,
                            overflowY: 'auto',
                            maxHeight: 'calc(100% - 50px)',
                        }}>
                            <Droppable droppableId="new">
                                {(provided, snapshot) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        sx={{
                                            backgroundColor: snapshot.isDraggingOver ? '#e0f7fa' : 'transparent',
                                            border: snapshot.isDraggingOver ? '2px dashed #00796b' : 'none',
                                            marginTop: '5px',
                                        }}
                                    >
                                        {newPipelines.length > 0 ? newPipelines.map((pipeline, index) => (
                                            <Draggable key={pipeline.id} draggableId={pipeline.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <Card
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                        sx={{
                                                            marginBottom: 1,
                                                            padding: 2,
                                                            boxShadow: 'none',
                                                            position: 'relative',
                                                            transform: snapshot.isDragging ? 'rotate(5deg)' : 'none',
                                                            transition: 'transform 0.2s ease',
                                                            backgroundColor: '#F3F4F6',
                                                        }}
                                                        onClick={() => handleCardClick(pipeline)}
                                                    >
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    bgcolor: pipeline.sex === 'Perempuan' ? '#ffebee' : '#e3f2fd',
                                                                    borderRadius: '50%',
                                                                    color: pipeline.sex === 'Perempuan' ? '#f48fb1' : '#64b5f6',
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faUser} size="2x" />
                                                            </Box>
                                                            <Box sx={{ flex: 1, marginLeft: 2 }}>
                                                                <Typography variant="h7">{pipeline.name}</Typography>
                                                                <Grid2 container spacing={1} sx={{ marginTop: 1 }}>
                                                                    <Grid2 size xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <FontAwesomeIcon icon={faPhone} style={{ fontSize: '0.9rem', color: '#333' }} />
                                                                        <Typography variant="body2" sx={{ color: '#333', marginLeft: 1 }}>{pipeline.phone}</Typography>
                                                                    </Grid2>
                                                                </Grid2>
                                                                <Box
                                                                    sx={{
                                                                        marginTop: 1,
                                                                        borderRadius: '8px',
                                                                        padding: '0 8px',
                                                                        display: 'inline-block',
                                                                        backgroundColor: getTypeBackgroundColor(pipeline.type),
                                                                        color: getTypeTextColor(pipeline.type),
                                                                    }}
                                                                >
                                                                    {pipeline.type}
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ position: 'absolute', top: 5, right: 7, display: 'flex', gap: 1 }}>
                                                                <FontAwesomeIcon
                                                                    icon={faPenToSquare}
                                                                    style={{ fontSize: '1rem', cursor: 'pointer', color: '#DDD' }}
                                                                />
                                                                <FontAwesomeIcon
                                                                    icon={faTrash}
                                                                    style={{ fontSize: '1rem', cursor: 'pointer', color: '#DDD' }}
                                                                    onClick={() => handleDeletePipeline(pipeline.id, "NEW")}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Card>
                                                )}
                                            </Draggable>
                                        )) : (
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                textAlign: 'center'
                                            }}>
                                                <Typography>No data available</Typography>
                                            </Box>
                                        )}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </Card>
                    </Grid2>
                    <Grid2 size={2.4} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Card sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px',
                            backgroundColor: '#fff',
                            position: 'relative',
                            color: '#ddd',
                            borderRadius: '4px 4px 0 0',
                            border: `1px solid ${grey[200]}`,
                            boxShadow: 'none',
                            height: '50px'
                        }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                New
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingLeft: '5px' }}>
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    style={{ fontSize: '1.5rem', cursor: 'pointer', color: '#ddd' }}
                                />
                            </Box>
                        </Card>
                        <Card sx={{
                            backgroundColor: '#fff',
                            boxShadow: 'none',
                            position: 'relative',
                            flexGrow: 1,
                            padding: 1.5,
                            border: `1px solid ${grey[200]}`,
                            borderTop: 'none',
                            marginTop: -1,
                            height: '100%',
                        }}>
                            <Droppable droppableId="followUp">
                                {(provided, snapshot) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        sx={{
                                            backgroundColor: snapshot.isDraggingOver ? '#bbdefb' : 'transparent',
                                            border: snapshot.isDraggingOver ? '2px dashed #0288d1' : 'none',
                                            marginTop: '5px',
                                        }}
                                    >
                                        {followUpPipelines.length > 0 ? followUpPipelines.map((pipeline, index) => (
                                            <Draggable key={pipeline.id} draggableId={pipeline.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <Card
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                        sx={{
                                                            marginBottom: 1,
                                                            padding: 2,
                                                            boxShadow: 'none',
                                                            position: 'relative',
                                                            transform: snapshot.isDragging ? 'rotate(5deg)' : 'none',
                                                            transition: 'transform 0.2s ease',
                                                            backgroundColor: '#F3F4F6'
                                                        }}
                                                        onClick={() => handleCardClick(pipeline)}
                                                    >
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    bgcolor: pipeline.sex === 'Perempuan' ? '#ffebee' : '#e3f2fd',
                                                                    borderRadius: '50%',
                                                                    color: pipeline.sex === 'Perempuan' ? '#f48fb1' : '#64b5f6',
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faUser} size="2x" />
                                                            </Box>
                                                            <Box sx={{ flex: 1, marginLeft: 2 }}>
                                                                <Typography variant="h7">{pipeline.name}</Typography>
                                                                <Grid2 container spacing={1} sx={{ marginTop: 1 }}>
                                                                    <Grid2 size xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <FontAwesomeIcon icon={faPhone} style={{ fontSize: '0.9rem', color: '#333' }} />
                                                                        <Typography variant="body2" sx={{ color: '#333', marginLeft: 1 }}>{pipeline.phone}</Typography>
                                                                    </Grid2>
                                                                </Grid2>
                                                                <Box
                                                                    sx={{
                                                                        marginTop: 1,
                                                                        borderRadius: '8px',
                                                                        padding: '0 8px',
                                                                        display: 'inline-block',
                                                                        backgroundColor: getTypeBackgroundColor(pipeline.type),
                                                                        color: getTypeTextColor(pipeline.type),
                                                                    }}
                                                                >
                                                                    {pipeline.type}
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ position: 'absolute', top: 5, right: 7, display: 'flex', gap: 1 }}>
                                                                <FontAwesomeIcon
                                                                    icon={faPenToSquare}
                                                                    style={{ fontSize: '1rem', cursor: 'pointer', color: '#DDD' }}
                                                                />
                                                                <FontAwesomeIcon
                                                                    icon={faTrash}
                                                                    style={{ fontSize: '1rem', cursor: 'pointer', color: '#DDD' }}
                                                                    onClick={() => handleDeletePipeline(pipeline.id, "NEW")}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Card>
                                                )}
                                            </Draggable>
                                        )) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <Typography>No data available</Typography>
                                            </Box>
                                        )}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </Card>
                    </Grid2>
                    <Grid2 size={2.4} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Card sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px',
                            backgroundColor: '#fff',
                            position: 'relative',
                            color: '#ddd',
                            borderRadius: '4px 4px 0 0',
                            border: `1px solid ${grey[200]}`,
                            boxShadow: 'none',
                            height: '50px'
                        }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                New
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingLeft: '5px' }}>
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    style={{ fontSize: '1.5rem', cursor: 'pointer', color: '#ddd' }}
                                />
                            </Box>
                        </Card>
                        <Card sx={{
                            backgroundColor: '#fff',
                            boxShadow: 'none',
                            position: 'relative',
                            flexGrow: 1,
                            padding: 1.5,
                            border: `1px solid ${grey[200]}`,
                            borderTop: 'none',
                            marginTop: -1,
                            height: '100%',
                        }}>
                            <Droppable droppableId="inProcess">
                                {(provided, snapshot) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        sx={{
                                            backgroundColor: snapshot.isDraggingOver ? '#ffe0b2' : 'transparent',
                                            border: snapshot.isDraggingOver ? '2px dashed #f57c00' : 'none',
                                            marginTop: '5px',
                                        }}
                                    >
                                        {inProcessPipelines.length > 0 ? inProcessPipelines.map((pipeline, index) => (
                                            <Draggable key={pipeline.id} draggableId={pipeline.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <Card
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                        sx={{
                                                            marginBottom: 1,
                                                            padding: 2,
                                                            boxShadow: 'none',
                                                            position: 'relative',
                                                            transform: snapshot.isDragging ? 'rotate(5deg)' : 'none',
                                                            transition: 'transform 0.2s ease',
                                                            backgroundColor: '#F3F4F6'
                                                        }}
                                                        onClick={() => handleCardClick(pipeline)}
                                                    >
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    bgcolor: pipeline.sex === 'Perempuan' ? '#ffebee' : '#e3f2fd',
                                                                    borderRadius: '50%',
                                                                    color: pipeline.sex === 'Perempuan' ? '#f48fb1' : '#64b5f6',
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faUser} size="2x" />
                                                            </Box>
                                                            <Box sx={{ flex: 1, marginLeft: 2 }}>
                                                                <Typography variant="h7">{pipeline.name}</Typography>
                                                                <Grid2 container spacing={1} sx={{ marginTop: 1 }}>
                                                                    <Grid2 size xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <FontAwesomeIcon icon={faPhone} style={{ fontSize: '0.9rem', color: '#333' }} />
                                                                        <Typography variant="body2" sx={{ color: '#333', marginLeft: 1 }}>{pipeline.phone}</Typography>
                                                                    </Grid2>
                                                                </Grid2>
                                                                <Box
                                                                    sx={{
                                                                        marginTop: 1,
                                                                        borderRadius: '8px',
                                                                        padding: '0 8px',
                                                                        display: 'inline-block',
                                                                        backgroundColor: getTypeBackgroundColor(pipeline.type),
                                                                        color: getTypeTextColor(pipeline.type),
                                                                    }}
                                                                >
                                                                    {pipeline.type}
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ position: 'absolute', top: 5, right: 7, display: 'flex', gap: 1 }}>
                                                                <FontAwesomeIcon
                                                                    icon={faPenToSquare}
                                                                    style={{ fontSize: '1rem', cursor: 'pointer', color: '#DDD' }}
                                                                />
                                                                <FontAwesomeIcon
                                                                    icon={faTrash}
                                                                    style={{ fontSize: '1rem', cursor: 'pointer', color: '#DDD' }}
                                                                    onClick={() => handleDeletePipeline(pipeline.id, "NEW")}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Card>
                                                )}
                                            </Draggable>
                                        )) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <Typography>No data available</Typography>
                                            </Box>
                                        )}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </Card>
                    </Grid2>
                    <Grid2 size={2.4} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Card sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px',
                            backgroundColor: '#fff',
                            position: 'relative',
                            color: '#ddd',
                            borderRadius: '4px 4px 0 0',
                            border: `1px solid ${grey[200]}`,
                            boxShadow: 'none',
                            height: '50px'
                        }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                New
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingLeft: '5px' }}>
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    style={{ fontSize: '1.5rem', cursor: 'pointer', color: '#ddd' }}
                                />
                            </Box>
                        </Card>
                        <Card sx={{
                            backgroundColor: '#fff',
                            boxShadow: 'none',
                            position: 'relative',
                            flexGrow: 1,
                            padding: 1.5,
                            border: `1px solid ${grey[200]}`,
                            borderTop: 'none',
                            marginTop: -1,
                            height: '100%',
                        }}>
                            <Droppable droppableId="closed">
                                {(provided, snapshot) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        sx={{
                                            backgroundColor: snapshot.isDraggingOver ? '#f8bbd0' : 'transparent',
                                            border: snapshot.isDraggingOver ? '2px dashed #d32f2f' : 'none',
                                            marginTop: '5px',
                                        }}
                                    >
                                        {closedPipelines.length > 0 ? closedPipelines.map((pipeline, index) => (
                                            <Draggable key={pipeline.id} draggableId={pipeline.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <Card
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                        sx={{
                                                            marginBottom: 1,
                                                            padding: 2,
                                                            boxShadow: 'none',
                                                            position: 'relative',
                                                            transform: snapshot.isDragging ? 'rotate(5deg)' : 'none',
                                                            transition: 'transform 0.2s ease',
                                                            backgroundColor: '#F3F4F6'
                                                        }}
                                                        onClick={() => handleCardClick(pipeline)}
                                                    >
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    bgcolor: pipeline.sex === 'Perempuan' ? '#ffebee' : '#e3f2fd',
                                                                    borderRadius: '50%',
                                                                    color: pipeline.sex === 'Perempuan' ? '#f48fb1' : '#64b5f6',
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faUser} size="2x" />
                                                            </Box>
                                                            <Box sx={{ flex: 1, marginLeft: 2 }}>
                                                                <Typography variant="h7">{pipeline.name}</Typography>
                                                                <Grid2 container spacing={1} sx={{ marginTop: 1 }}>
                                                                    <Grid2 size xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <FontAwesomeIcon icon={faPhone} style={{ fontSize: '0.9rem', color: '#333' }} />
                                                                        <Typography variant="body2" sx={{ color: '#333', marginLeft: 1 }}>{pipeline.phone}</Typography>
                                                                    </Grid2>
                                                                </Grid2>
                                                                <Box
                                                                    sx={{
                                                                        marginTop: 1,
                                                                        borderRadius: '8px',
                                                                        padding: '0 8px',
                                                                        display: 'inline-block',
                                                                        backgroundColor: getTypeBackgroundColor(pipeline.type),
                                                                        color: getTypeTextColor(pipeline.type),
                                                                    }}
                                                                >
                                                                    {pipeline.type}
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ position: 'absolute', top: 5, right: 7, display: 'flex', gap: 1 }}>
                                                                <FontAwesomeIcon
                                                                    icon={faPenToSquare}
                                                                    style={{ fontSize: '1rem', cursor: 'pointer', color: '#DDD' }}
                                                                />
                                                                <FontAwesomeIcon
                                                                    icon={faTrash}
                                                                    style={{ fontSize: '1rem', cursor: 'pointer', color: '#DDD' }}
                                                                    onClick={() => handleDeletePipeline(pipeline.id, "NEW")}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Card>
                                                )}
                                            </Draggable>
                                        )) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <Typography>No data available</Typography>
                                            </Box>
                                        )}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </Card>
                    </Grid2>
                    <Grid2 size={2.4} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Card sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px',
                            backgroundColor: '#fff',
                            position: 'relative',
                            color: '#ddd',
                            borderRadius: '4px 4px 0 0',
                            border: `1px solid ${grey[200]}`,
                            boxShadow: 'none',
                            height: '50px'
                        }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                New
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingLeft: '5px' }}>
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    style={{ fontSize: '1.5rem', cursor: 'pointer', color: '#ddd' }}
                                />
                            </Box>
                        </Card>
                        <Card sx={{
                            backgroundColor: '#fff',
                            boxShadow: 'none',
                            position: 'relative',
                            flexGrow: 1, padding: 1.5,
                            border: `1px solid ${grey[200]}`,
                            borderTop: 'none',
                            marginTop: -1,
                            height: '100%',
                        }}>
                            <Droppable droppableId="closed">
                                {(provided, snapshot) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        sx={{
                                            backgroundColor: snapshot.isDraggingOver ? '#f8bbd0' : 'transparent',
                                            border: snapshot.isDraggingOver ? '2px dashed #d32f2f' : 'none',
                                            marginTop: '5px',
                                        }}
                                    >
                                        {closedPipelines.length > 0 ? closedPipelines.map((pipeline, index) => (
                                            <Draggable key={pipeline.id} draggableId={pipeline.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <Card
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                        sx={{
                                                            marginBottom: 1,
                                                            padding: 2,
                                                            boxShadow: 'none',
                                                            position: 'relative',
                                                            transform: snapshot.isDragging ? 'rotate(5deg)' : 'none',
                                                            transition: 'transform 0.2s ease',
                                                            backgroundColor: '#F3F4F6'
                                                        }}
                                                        onClick={() => handleCardClick(pipeline)}
                                                    >
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    bgcolor: pipeline.sex === 'Perempuan' ? '#ffebee' : '#e3f2fd',
                                                                    borderRadius: '50%',
                                                                    color: pipeline.sex === 'Perempuan' ? '#f48fb1' : '#64b5f6',
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faUser} size="2x" />
                                                            </Box>
                                                            <Box sx={{ flex: 1, marginLeft: 2 }}>
                                                                <Typography variant="h7">{pipeline.name}</Typography>
                                                                <Grid2 container spacing={1} sx={{ marginTop: 1 }}>
                                                                    <Grid2 size xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <FontAwesomeIcon icon={faPhone} style={{ fontSize: '0.9rem', color: '#333' }} />
                                                                        <Typography variant="body2" sx={{ color: '#333', marginLeft: 1 }}>{pipeline.phone}</Typography>
                                                                    </Grid2>
                                                                </Grid2>
                                                                <Box
                                                                    sx={{
                                                                        marginTop: 1,
                                                                        borderRadius: '8px',
                                                                        padding: '0 8px',
                                                                        display: 'inline-block',
                                                                        backgroundColor: getTypeBackgroundColor(pipeline.type),
                                                                        color: getTypeTextColor(pipeline.type),
                                                                    }}
                                                                >
                                                                    {pipeline.type}
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ position: 'absolute', top: 5, right: 7, display: 'flex', gap: 1 }}>
                                                                <FontAwesomeIcon
                                                                    icon={faPenToSquare}
                                                                    style={{ fontSize: '1rem', cursor: 'pointer', color: '#DDD' }}
                                                                />
                                                                <FontAwesomeIcon
                                                                    icon={faTrash}
                                                                    style={{ fontSize: '1rem', cursor: 'pointer', color: '#DDD' }}
                                                                    onClick={() => handleDeletePipeline(pipeline.id, "NEW")}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Card>
                                                )}
                                            </Draggable>
                                        )) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <Typography>No data available</Typography>
                                            </Box>
                                        )}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </Card>
                    </Grid2>
                </Grid2>
            </DragDropContext>
            <Modal
                open={openModalPipeline}
                onClose={handleCloseModalPipeline}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '3%',
                    right: '2%',
                    transform: 'translate(0, 0)',
                    bgcolor: '#FFFFFF',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    borderRadius: '10px',
                    p: 4,
                    width: '400px',
                    height: '100%',
                    maxHeight: '86vh',
                    overflow: 'auto',
                    border: 'none',
                    outline: 'none',
                }}>
                    {selectedPipeline && (
                        <>
                            <Typography variant="h6" id="modal-title">{selectedPipeline.name}</Typography>
                            <Typography variant="body2" sx={{ marginTop: 1 }} id="modal-description">
                                Phone: {selectedPipeline.phone}
                            </Typography>
                            <Typography variant="body2" sx={{ marginTop: 1 }}>
                                Type: {selectedPipeline.type}
                            </Typography>
                            <Typography variant="body2" sx={{ marginTop: 1 }}>
                                Gender: {selectedPipeline.sex}
                            </Typography>
                        </>
                    )}
                </Box>
            </Modal>
        </Box >
    );
};

export default Pipeline;