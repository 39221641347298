import React from "react";
// import Navbar from '../../components/klinik/Navbar';

const Layout = ({ children }) => {
    return (
        <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                {/* <Navbar /> */}
                <main style={{ flexGrow: 1, overflowY: 'auto', padding: '20px', backgroundColor: '#F3F4F6' }}>
                    {children}
                </main>
            </div>
        </React.Fragment>
    );
};

export default Layout;