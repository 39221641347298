import React from 'react';
import TransaksiPasien from '../../components/klinik/TransaksiPasien';
import Layout from '../../pages/klinik/Layout';

export const TransaksiPasienPage = () => {
    return (
        <Layout>
            <TransaksiPasien />
        </Layout>
    )
}
export default TransaksiPasienPage;